import * as search from './landing/store/search.reducers';

import {
  ActivatedRouteSnapshot,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import {
  DS_APP_WRAPPER_CONFIG,
  DsAppWrapperConfig,
} from '@design-system/feature/app-wrapper-v2';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { HIGHLIGHT_OPTIONS, HighlightModule } from 'ngx-highlightjs';

import { NgModule, inject } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { DsBreadcrumbsModule } from '@design-system/components/breadcrumbs';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { AppWrapperModule } from '@design-system/feature/app-wrapper-v2';
import { DsPageModule } from '@design-system/feature/page';
import { DsPrintModule } from '@design-system/feature/print';
import { AuthGuard } from '@features/auth';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { GoogleAnalyticsClickListenerDirective } from '@shared-lib/google-analytics';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core.module';
import { LandingComponent } from './landing/landing.component';
import { FindRoutePipe } from './landing/pipe/find-route.pipe';
import { SearchEffects } from './landing/store';
import { ChristmasComponent } from './shared/christmas/christmas.component';
import { SharedModule } from './shared/shared.module';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RootState {}

export const rootReducers: ActionReducerMap<RootState> = {};

const appRoutes: Routes = [
  {
    path: '',
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
      {
        path: 'landing',
        component: LandingComponent,
      },
      {
        path: 'components',
        loadChildren: () =>
          import('./components/components.module').then(
            (m) => m.ComponentsModule,
          ),
        data: {
          breadcrumb: 'Components',
          backUrl: 'overview',
        },
      },
      {
        path: 'setup',
        loadChildren: () =>
          import('./setup/setup-routes').then((r) => r.SETUP_ROUTES),
        data: {
          breadcrumb: 'Setup',
          backUrl: 'setup',
        },
      },
      {
        path: 'layout',
        data: {
          breadcrumb: 'Layout',
        },
        loadChildren: () =>
          import('./layout/layout.module').then((m) => m.LayoutModule),
      },
      {
        path: 'form-controls',
        data: {
          breadcrumb: 'Form-Controls',
        },
        loadChildren: () =>
          import('./form-controls/form-controls.module').then(
            (m) => m.FormControlsModule,
          ),
      },
      {
        path: 'cdk',
        data: {
          breadcrumb: 'CDK',
        },
        loadChildren: () => import('./cdk/cdk.module').then((m) => m.CdkModule),
      },
      {
        path: 'foundation',
        data: {
          breadcrumb: 'Foundation',
          backUrl: 'landing',
        },
        loadChildren: () =>
          import('./foundation/foundation.module').then(
            (m) => m.FoundationModule,
          ),
      },
      {
        path: 'xmas',
        component: ChristmasComponent,
      },
      {
        path: 'callback',
        children: [],
      },
      {
        path: '**',
        redirectTo: 'landing',
      },
      {
        path: 'special/permissiondenied',
        redirectTo: 'landing',
      },
    ],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];

@NgModule({
  declarations: [AppComponent, LandingComponent, FindRoutePipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes, {
      preloadingStrategy: PreloadAllModules,
    }),
    StoreModule.forRoot(rootReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreModule.forFeature(search.key, search.reducer),
    EffectsModule.forFeature([SearchEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: !environment.production,
      connectInZone: true,
    }),
    SharedModule,
    SharedLibFeatTranslationModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    // Material Module
    MatIconModule,
    // Shared
    PdLetDirectiveModule,
    DsPageModule,
    DsBreadcrumbsModule,
    DsPlaceholderModule,
    MatFormFieldModule,
    MatInputModule,
    // Basic
    CoreModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: (): DsAppWrapperConfig => ({
          environment: environment.environment,
          apiBasePath: environment.palfinger_api_management,
          isNativeApp: false,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Design System Documentation',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Design System Documentation',
          appHelpPath: environment.LINKS.APP_HELP_PATH,
          appNewsPath: environment.LINKS.APP_NEWS_PATH,
          statusBannerProductsToCheck: [],
        }),
      },
    ]),
    DsPrintModule,
    GoogleMapsModule,
    HighlightModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    GoogleAnalyticsClickListenerDirective,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          typescript: () => import('highlight.js/lib/languages/typescript'),
          css: () => import('highlight.js/lib/languages/css'),
          xml: () => import('highlight.js/lib/languages/xml'),
        },
      },
    },
  ],
})
export class AppModule {}
