import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsSnackbarComponent } from '@design-system/feature/snackbar';
import { HighlightPlusModule } from 'ngx-highlightjs/plus';
import { ChristmasComponent } from './christmas/christmas.component';
import { CodeExampleComponent } from './code-example/code-example.component';
import { CodeSnippetComponent } from './code-snippet/code-snippet.component';
import { PreviewComponent } from './preview/preview.component';
import { TableOfContentsComponent } from './table-of-contents/table-of-contents.component';

const components = [ChristmasComponent];

@NgModule({
  declarations: [...components],
  exports: [
    ...components,
    PreviewComponent,
    DsSpacingModule,
    TableOfContentsComponent,
    CodeExampleComponent,
    CodeSnippetComponent,
  ],
  imports: [
    DragDropModule,
    FlexLayoutModule,
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatTooltipModule,
    MatIconModule,
    HighlightPlusModule,
    MatCardModule,
    MatButtonModule,
    DsSnackbarComponent,
    MatDialogModule,
    RouterModule,
    TableOfContentsComponent,
    CodeSnippetComponent,
    CodeExampleComponent,
    PreviewComponent,
  ],
})
export class SharedModule {}
