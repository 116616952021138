import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  SecurityContext,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import {
  DsSnackbarService,
  DsSnackbarType,
} from '@design-system/feature/snackbar';
import { HighlightModule } from 'ngx-highlightjs';
import { Observable, Subject } from 'rxjs';
import { map, shareReplay, takeUntil, tap } from 'rxjs/operators';

export interface Example {
  urlRelativeToApp: string;
  type: 'HTML' | 'TS' | 'SCSS';
}

@Component({
  selector: 'ds-docu-code-example',
  templateUrl: './code-example.component.html',
  styleUrls: ['./code-example.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    HighlightModule,
  ],
})
export class CodeExampleComponent implements OnDestroy {
  _examples: Example[];
  @Input() heading = 'Please provide a title';
  @Input() isOverflowHidden = false;
  @Input() hasBackground = false;
  @Input() set examples(val: Example[]) {
    this._examples = val;
    this.getTextContent(0);
  }
  exampleText$: Observable<string>;
  showCode: boolean;

  private _cache: Record<string, Observable<string>> = {};
  private destroy$ = new Subject<void>();

  constructor(
    private _domSanitizer: DomSanitizer,
    public _elementRef: ElementRef,
    private _http: HttpClient,
    private readonly clipboard: Clipboard,
    private readonly snackbar: DsSnackbarService,
  ) {}

  getTextContent(index: number): void {
    this.exampleText$ = this.fetchDocument(
      'assets/examples/' + this._examples[index].urlRelativeToApp,
    ).pipe(
      map((document) =>
        document.replace(
          /href="#([^"]*)"/g,
          (_m: string, fragmentUrl: string) => {
            const absoluteUrl = `${location.pathname}#${fragmentUrl}`;
            return `href="${this._domSanitizer.sanitize(
              SecurityContext.URL,
              absoluteUrl,
            )}"`;
          },
        ),
      ),
      takeUntil(this.destroy$),
    );
  }

  copySource(example: string) {
    if (this.clipboard.copy(example)) {
      this.snackbar.queue('Code copied', {
        type: DsSnackbarType.Success,
      });
    } else {
      this.snackbar.queue('Copy failed. Please try again!', {
        type: DsSnackbarType.Error,
      });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  toggleShowCode(): void {
    this.showCode = !this.showCode;
  }

  private fetchDocument(url: string): Observable<string> {
    if (this._cache[url]) {
      return this._cache[url];
    }

    const stream = this._http
      .get(url, { responseType: 'text' })
      .pipe(shareReplay(1));
    return stream.pipe(tap(() => (this._cache[url] = stream)));
  }
}
